import { useEffect, useState } from "react"
import { Col, Row, Form, Button, notification } from "antd"
import moment from "moment"
import { func, object, string } from "prop-types"
import { FormSectionBuilder } from "../DynamicForm/FormSectionBuilder"
import {
  // CREATE_PATIENT_OPTIONS_DATA,
  populateSavedCaregiverForm,
  getCaregiverList,
  DELIVERY_ADDRESS_FIELDS,
} from "../CreatePatientForm/constants"
import { useMutateData } from "../../../utils/hooks"
import { editPatientByUidUrl, fetchPatientDropdownOptions, getPatientForm } from "../../../api/endpoints"
import "./styles.scss"
import { getDropdowns } from "../../../utils/common"

export const EditPatientForm = (props) => {
  const {
    editIndex = "",
    patientId = "",
    discardCallback,
    patientData = {},
    afterSave,
    editDeliveryAddressIndex = "",
  } = props
  const [EDIT_PATIENT_FORM, setEditPatientForm] = useState([])
  const [formDetails, setFormDetails] = useState({})
  const [optionsData, setOptionsData] = useState({})
  const [apiCall, { loading }] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()
  const getDropdownOptions = async ({ key = "" }) =>
    new Promise((resolve) => {
      apiCall({
        url: fetchPatientDropdownOptions(),
        method: "POST",
        payload: { key },
      })
        .then((response) => {
          const { data = {} } = response
          const { error = false } = data
          if (error) {
            resolve([])
          } else {
            resolve(data)
          }
        })
        .catch(() => resolve([]))
    })

  const getCreatePatientForm = async () => {
    const { data = {} } = await apiCall({
      url: getPatientForm(),
      method: "POST",
      payload: {
        type: "UPDATE_FORM",
      },
    })
    const { requirements = {} } = data
    const dropdownKeys = getDropdowns(requirements)
    const optionsCalls = await dropdownKeys.map((key) => getDropdownOptions({ key }))
    Promise.all(optionsCalls).then((values) => {
      let dropdownValues = ""
      dropdownValues = {}
      dropdownKeys.forEach((key, i) => {
        if (key) {
          dropdownValues[key] = values[i]
        }
      })
      const dValues = {
        ...dropdownValues,
        // ...CREATE_PATIENT_OPTIONS_DATA,
      }
      setOptionsData(dValues)
    })
    setFormDetails(data?.requirements || {})
    setEditPatientForm(data?.requirements?.sections || [])
  }
  useEffect(() => {
    getCreatePatientForm()
  }, [])
  const getCurrentSectionDetails = () => {
    const sections = EDIT_PATIENT_FORM
    const currentSection = sections[editIndex] || {}
    return currentSection
  }
  const getCurrentSectionFields = () => {
    const sections = EDIT_PATIENT_FORM
    const currentSection = sections[editIndex] || {}
    const currentSectionFields = currentSection.steps || []
    return currentSectionFields
  }
  const showToastMessage = (data) => {
    const { message = "", error = false } = data
    if (error) {
      toastMessage.error({
        key: "editPatientSuccess",
        message: "Error",
        description: message,
      })
    } else {
      toastMessage.success({
        key: "editPatientError",
        message: "Success",
        description: message,
      })
    }
  }
  const clearAddressForm = () => {
    DELIVERY_ADDRESS_FIELDS.map((dField) => {
      const key = dField?.step_name || ""
      if (key) {
        form.setFieldValue(key, "")
      }
      return key
    })
  }
  const editApiCall = async (editData) => {
    const { data = {} } = await apiCall({
      url: editPatientByUidUrl(),
      method: "POST",
      payload: { patient_id: patientId, ...editData },
    })
    showToastMessage(data)
    clearAddressForm()
    if (!data.error) {
      setTimeout(() => {
        discardCallback()
        afterSave()
      }, 2000)
    }
  }
  const editPatientSection = async (editPatientData = {}) => {
    let editData = ""
    editData = editPatientData
    const currentSection = getCurrentSectionDetails()
    const currentSectionName = currentSection?.name || ""
    let callApi = ""
    callApi = true
    if (editPatientData.date_of_birth) {
      // editData.date_of_birth = editPatientData.date_of_birth.format("YYYY-MM-DD")
    }
    if (editPatientData.docs_received_on) {
      editData.docs_received_on = editPatientData.docs_received_on.format("YYYY-MM-DD")
    }
    if (editPatientData.patient_consent_date) {
      editData.patient_consent_date = editPatientData.patient_consent_date.format("YYYY-MM-DD")
    }
    if (currentSectionName === "caregiver_info") {
      const editedCgObjList = getCaregiverList(editPatientData)
      const existingCgObjList = patientData?.care_giver_details || []
      const updatedCgObjList = editedCgObjList.map((cgObj, cgIndex) => {
        const cgName = cgObj?.care_giver_name?.trim() || ""
        const cgNumber = cgObj?.care_giver_phone_number?.trim() || ""
        const cgEmail = cgObj?.email?.trim() || ""
        const cgRelationship = cgObj?.relationship || ""
        const existingCgObj = existingCgObjList[cgIndex] || {}
        return {
          ...existingCgObj,
          care_giver_name: cgName,
          care_giver_phone_number: cgNumber,
          email: cgEmail,
          relationship: cgRelationship,
          ordering: cgIndex + 1,
          patient_id: patientId,
        }
      })
      editData = {
        caregiver_details: updatedCgObjList,
      }
    }
    if (currentSectionName === "perma_addr_section") {
      const savedRegAddress = patientData?.permanent_address || {}
      editData = {
        registered_address: {
          ...savedRegAddress,
          address_line_1: editPatientData?.regd_address_line_1?.trim() || "",
          address_line_2: editPatientData?.regd_address_line_2?.trim() || "",
          city: editPatientData?.regd_city?.trim() || "",
          pincode: editPatientData?.regd_pincode || "",
          patient_id: patientId,
          sub_type: editPatientData?.regd_address_type || "",
          state: editPatientData?.regd_state || "",
        },
      }
      const hasKeyVal = Object.values(editPatientData).some((val) => val)
      if (!hasKeyVal) {
        callApi = false
      }
    }
    if (currentSectionName === "delivery_address_section") {
      const isEditAddr = editDeliveryAddressIndex !== ""
      const savedDeliveryAddress = isEditAddr ? patientData?.delivery_addresses[editDeliveryAddressIndex] : {}
      let delAddrObj = ""
      delAddrObj = {
        ...savedDeliveryAddress,
        address_line_1: editPatientData?.delivery_address_line_1?.trim() || "",
        address_line_2: editPatientData?.delivery_address_line_2?.trim() || "",
        city: editPatientData?.delivery_city?.trim() || "",
        pincode: editPatientData?.delivery_pincode || "",
        sub_type: editPatientData?.delivery_address_type || "",
        proof_file: editPatientData?.delivery_address_proof || "",
        state: editPatientData?.delivery_state || "",
        patient_id: patientId,
      }
      if (isEditAddr) {
        delAddrObj.patient_id = patientId
      }
      editData = {
        delivery_addresses: [delAddrObj],
      }
      const hasKeyVal = Object.values(editPatientData).some((val) => val)
      if (!hasKeyVal) {
        callApi = false
      }
    }
    if (currentSectionName === "patient_info") {
      if (!editPatientData.id_card_type) {
        editData.id_card_type = ""
      }
    }
    const exemptedSectionNames = ["perma_addr_section", "delivery_address_section", "caregiver_info"]
    if (!exemptedSectionNames.includes(currentSectionName)) {
      editData = {
        [currentSectionName]: editData,
      }
    }
    if (editData?.caregiver_details?.length === 0) {
      callApi = false
    }
    if (callApi) {
      editApiCall(editData)
    } else {
      discardCallback()
    }
  }
  const getSavedData = () => {
    let savedData = ""
    savedData = patientData
    if (patientData.date_of_birth) {
      savedData.date_of_birth = moment(patientData.date_of_birth)
    }
    if (patientData?.lead_date) {
      savedData.lead_date = moment(patientData.lead_date)
    }
    if (patientData.patient_consent_date) {
      savedData.patient_consent_date = moment(patientData.patient_consent_date)
    }
    if (patientData.docs_received_on) {
      savedData.docs_received_on = moment(patientData.docs_received_on)
    }
    const cgFields = populateSavedCaregiverForm(patientData)
    const regAddress = patientData?.permanent_address || {}
    const regAddressFields = {
      regd_address_line_1: regAddress?.address_line_1 || "",
      regd_address_line_2: regAddress?.address_line_2 || "",
      regd_city: regAddress?.city || null,
      regd_pincode: regAddress?.pincode || "",
      regd_address_type: regAddress?.sub_type || null,
      regd_state: regAddress?.state || null,
    }
    const delAddress = patientData?.delivery_addresses?.[editDeliveryAddressIndex] || {}
    const delAddressFields = {
      delivery_address_line_1: delAddress?.address_line_1 || "",
      delivery_address_line_2: delAddress?.address_line_2 || "",
      delivery_city: delAddress?.city || null,
      delivery_pincode: delAddress?.pincode || "",
      delivery_state: delAddress?.state || null,
      delivery_address_type: delAddress?.sub_type || null,
      delivery_address_proof: delAddress?.proof_file || "",
    }
    savedData = {
      ...savedData,
      ...cgFields,
      ...regAddressFields,
      ...delAddressFields,
    }
    return savedData
  }
  const savedPatientData = getSavedData()
  const currentCaregiverCount = savedPatientData?.care_giver_details?.length || 1
  return (
    <Row className="w-100">
      {contextHolder}
      <Col span={6} push={3}>
        <Form
          className="dynamic-form-fields-container"
          form={form}
          layout="vertical"
          onFinish={editPatientSection}
          initialValues={savedPatientData}
        >
          <FormSectionBuilder
            section={getCurrentSectionDetails()}
            fields={getCurrentSectionFields()}
            optionsData={optionsData}
            formType="editPatient"
            uploadFileParams={{ patientId }}
            formObj={form}
            additionalData={{ currentCaregiverCount }}
            stateVars={formDetails?.state_vars || {}}
            fieldSets={formDetails.field_sets || {}}
          />
          <Row className="w-100 form-btns-row" align="middle">
            <Col span={4}>
              <Button disabled={loading} className="custom-secondary-btn" onClick={discardCallback}>
                Discard Changes
              </Button>
            </Col>
            <Col span={4} push={4} className="text-right">
              <Button loading={loading} type="primary" htmlType="submit" className="form-submit-btn">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

EditPatientForm.defaultProps = {
  editIndex: "",
  patientId: "",
  discardCallback: () => {},
  patientData: {},
  afterSave: () => {},
  editDeliveryAddressIndex: "",
}

EditPatientForm.propTypes = {
  editIndex: string,
  patientId: string,
  discardCallback: func,
  // eslint-disable-next-line react/forbid-prop-types
  patientData: object,
  afterSave: func,
  editDeliveryAddressIndex: string,
}
