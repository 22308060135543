import { GUEST_ROUTE, PRIVATE_ROUTE, PUBLIC_ROUTE } from "./common"

export const ROUTES = {
  GUEST: {
    path: "/doctor-consent",
    route: GUEST_ROUTE,
  },
  LOGIN: {
    path: "/login",
    route: PUBLIC_ROUTE,
  },
  PATIENT: {
    path: "/patient",
    route: PRIVATE_ROUTE,
    children: {
      PATIENT_DASHBOARD: {
        path: "/dashboard",
      },
      ADD_PATIENT: {
        path: "/create",
      },
      VIEW_PATIENT: {
        path: "/view/:uid",
      },
      PATIENT_PROGRAM_DASHBOARD: {
        path: "/program-dashboard",
      },
      VIEW_PATIENT_PROGRAM_DETAILS: {
        path: "/view-program/:uid/:programid",
      },
      GENERATE_PAID_ORDER: {
        path: "/generate-order/:ordertype/:uid/:programid",
      },
      PAID_ORDERS_DASHBOARD: {
        path: "/orders/paid",
      },
      FOC_ORDERS_DASHBOARD: {
        path: "/orders/foc",
      },
      ADVERSE_EVENTS_DASHBOARD: {
        path: "/adverse-events-dashboard",
      },
      CREATE_AE: {
        path: "/create-ae/:uid/:programid/:ppid",
      },
      EDIT_AE: {
        path: "/edit-ae/:uid/:programid/:ppid",
      },
    },
  },
  PHYSICAL_VERIFICATION: {
    path: "/pv",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/dashboard",
      },
    },
  },
  EKYC: {
    path: "/ekyc",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/dashboard",
      },
    },
  },
  LAB_ORDERS: {
    path: "/lab-orders",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/dashboard",
      },
    },
  },
  MASTER_MANAGEMENT: {
    path: "/master-management",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/:type/all",
      },
      CREATE_FORM: {
        path: "/:type/create/:pid",
      },
      EDIT_FORM: {
        path: "/:type/edit/:id/:pid",
      },
      VIEW_DETAILS: {
        path: "/:type/view/:id/:pid",
      },
    },
  },
  COMMUNICATION: {
    path: "/communication",
    route: PRIVATE_ROUTE,
    children: {
      EMAIL: {
        path: "/email-dashboard",
      },
      SMS: {
        path: "/sms-dashboard",
      },
      EMAIL_DETAILS_PAGE: {
        path: "/email-details-page/:id",
      },
      SMS_DETAILS_PAGE: {
        path: "/sms-details-page/:id",
      },
    },
  },
  FIELD_COUNSELLOR: {
    path: "/fc",
    route: PRIVATE_ROUTE,
    children: {
      BULK_TAG: {
        path: "/fc-upload-dashboard",
      },
      PATIENT_INTERACTION_MODULE: {
        path: "/patient-interaction-module",
      },
      PATIENT_INTERACTION_DETAILS_PAGE: {
        path: "/patient-interaction-module/:id",
      },
      TASK_DASHBOARD: {
        path: "/task-dashboard",
      },
      TASK_DETAILS_PAGE: {
        path: "/task-details-page/:id",
      },
    },
  },
  PHLEBO_PORTAL: {
    path: "/phlebo",
    route: PRIVATE_ROUTE,
    children: {
      LOGIN: {
        path: "/login",
      },
      VERIFY_PV_ORDER_ID: {
        path: "/verify-pv-order",
      },
      MARK_PV_VERIFIED: {
        path: "/mark-pv-order-verified/:id/:pvid",
      },
      MARK_PV_INCOMPLETE: {
        path: "/mark-pv-order-incomplete/:id/:pvid",
      },
      RESCHEDULE_PV: {
        path: "/reschedule-pv-order/:id",
      },
    },
  },
  VISITOR: {
    path: "/visitor",
    route: GUEST_ROUTE,
    children: {
      AUTHORISATION: {
        path: "/authorisation/*",
      },
      ACKNOWLEDGEMENT: {
        path: "/acknowledgement",
      },
      OCR: {
        path: "/ocr/:ocrParams",
      },
    },
  },
  CLAIMS: {
    path: "/claims",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/dashboard",
      },
      DETAILS: {
        path: "/view/:claimid/:programid",
      },
      DETAILS_FROM_TABLE: {
        path: "/view-patient-claim-details/:patientid/:claimid/:programid",
      },
      DETAILS_FROM_DASHBOARD: {
        path: "/view-claim-details/:patient/:claimid/:programid",
      },
    },
  },
  COUNSELLING: {
    path: "/counselling",
    route: PRIVATE_ROUTE,
    children: {
      DASHBOARD: {
        path: "/dashboard",
      },
    },
  },
}

export const getHomePage = ({ currentSubdomain, role }) => {
  let url = null
  url = role === "PHLEBOTOMIST" ? `/phlebo/verify-pv-order` : ""
  if (currentSubdomain) {
    url = `/${currentSubdomain}/patient/dashboard`
    if (role === "PAP_MANAGER") {
      url = `/${currentSubdomain}/patient/program-dashboard`
    }
    if (role === "FINANCIAL_COUNSELLOR" || role === "WELLBEING_COUNSELLOR" || role === "DISEASE_COUNSELLOR") {
      url = `/${currentSubdomain}/counselling/dashboard`
    }
  }
  return url
}
