import { useState, useMemo, useEffect } from "react"
import { useSelector } from "react-redux"
import { Button, Col, Row } from "antd"
import { useMutateData } from "../../../utils/hooks"
import { getLabDataUrl, getPatientLabOrderDataUrl } from "../../../api/endpoints/labOrders"
import { DataTableWithPagination } from "../DataTableWithPagination"
import {
  canApproveLabOrder,
  canCloseLabOrder,
  canRejectLabOrder,
  canScheduleSamplePickup,
  getPermissions,
} from "../../../utils/common"
import "./styles.scss"
import { ExportButtonForDashboard } from "../ExportButtonForDashboard"
import { LabOrderModalContainer } from "../LabOrderModalContainer"
import { StatusTag } from "../StatusTag/OrderStatusTag"
import { PAID_ORDER_ICON } from "../../../constants/images"
import { useColumnsFormatting } from "../DataTableWithPagination/useColumnsFormatting"

export const LabOrdersDashboard = (props) => {
  const {
    source = null,
    programId = null,
    programApplicationId = null,
    toggleScheduleSamplePickupFormFlag = null,
    patientId = "",
  } = props
  const permissions = useSelector(getPermissions)
  const [labData, setLabData] = useState([])
  const [getFormattedColumns] = useColumnsFormatting()
  const [otherParams, setOtherParams] = useState({})
  const memorisedOtherParams = useMemo(() => otherParams, [otherParams])
  const [callApi, { loading }] = useMutateData()
  const [labDashboardColumns, setLabDashboardColumns] = useState({})
  const [enableExportFunctionality] = useState(source !== "profile")
  const canApproveLabTestOrder = useSelector(canApproveLabOrder)
  const canRejectLabTestOrder = useSelector(canRejectLabOrder)
  const canScheduleSamplePickUp = useSelector(canScheduleSamplePickup)
  const canCloseLabTestOrder = useSelector(canCloseLabOrder)
  const displayActionsColumn =
    canApproveLabTestOrder || canRejectLabTestOrder || canScheduleSamplePickUp || canCloseLabTestOrder

  const fetchLabOrdersData = async (params = {}) => {
    let payload = {}
    payload = {
      page_no: params?.page_number || 0,
      page_size: params?.page_size || 10,
      application_id: params?.application_id || 1,
      ...params,
    }
    if (source === "profile") {
      payload.program_id = programId
      delete payload.page_no
      delete payload.page_size
      delete payload.application_id
      payload.patient_program_application_id = programApplicationId
    }
    delete payload.page_number
    try {
      const { data = {} } = await callApi({
        url: source === "profile" ? getPatientLabOrderDataUrl() : getLabDataUrl(),
        method: "POST",
        payload,
      })
      setLabData(data)
      const dynamicColumnsObj = getFormattedColumns({ columns: data?.display_columns || [] })
      setLabDashboardColumns(dynamicColumnsObj)
    } catch (apiError) {
      console.log("Lab Dashboard Datatable error", apiError)
    }
  }

  const initiateSamplePickupFlow = (record) => {
    toggleScheduleSamplePickupFormFlag(true, record)
  }

  useEffect(() => {
    fetchLabOrdersData(otherParams)
  }, [setOtherParams])

  const getApproveModal = (record) => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="APPROVE"
      actionType="APPROVE"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={fetchLabOrdersData}
      patientId={patientId}
    />
  )

  const getRejectModal = (record) => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="REJECT"
      actionType="REJECT"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={fetchLabOrdersData}
      patientId={patientId}
    />
  )

  const getScheduleSampleBtn = (record) => (
    <Button className="lab-order-table-action-cta-styling " onClick={() => initiateSamplePickupFlow(record)}>
      SCHEDULE SAMPLE PICKUP
    </Button>
  )

  const getCloseModal = (record) => (
    <LabOrderModalContainer
      btnClass="lab-order-table-action-cta-styling "
      btnText="CLOSE"
      actionType="CLOSE"
      dataObj={record}
      ppaId={programApplicationId}
      programId={programId}
      updateLabOrderTable={fetchLabOrdersData}
      patientId={patientId}
    />
  )

  const getNextAvailableActions = (record) => {
    const actions = record?.next_available_actions?.filter((action) => permissions[action] || false)
    const actionRenderMap = {
      APPROVE_LAB_ORDER: getApproveModal,
      REJECT_LAB_ORDER: getRejectModal,
      SCHEDULE_SAMPLE_PICKUP: getScheduleSampleBtn,
      CLOSE_LAB_ORDER: getCloseModal,
    }
    return actions?.map((action) => {
      const actionMap = actionRenderMap[action]
      return actionMap(record)
    })
  }

  const addActionsColumn = () => {
    const actionsColumnExists = labDashboardColumns.some((column) => column.key === "actions")
    if (!actionsColumnExists && displayActionsColumn) {
      const tableColumnsTemp = [...labDashboardColumns]
      tableColumnsTemp.push({
        id: tableColumnsTemp.length + 1,
        key: "actions",
        label: "Actions",
        disableSearch: true,
        render: (_, record) => (
          <div>
            {getNextAvailableActions(record)}
            {canApproveLabTestOrder &&
              record?.status === "SAMPLE PICKUP SCHEDULED" &&
              getApproveModal(record)}
            {canRejectLabTestOrder && record?.status === "SAMPLE PICKUP SCHEDULED" && getRejectModal(record)}
            {record?.status === "REJECTED" ||
              (record?.status === "CLOSED" && <StatusTag text={record?.status || ""} />)}
            {canScheduleSamplePickUp && record?.status === "OPEN" && getScheduleSampleBtn(record)}
            {canCloseLabTestOrder && record?.status === "APPROVED" && getCloseModal(record)}
          </div>
        ),
      })

      setLabDashboardColumns(tableColumnsTemp)
    }
  }

  useEffect(() => {
    if (source === "profile" && labDashboardColumns && labDashboardColumns.length > 0) {
      addActionsColumn()
    }
  }, [labDashboardColumns])

  return (
    <div className="lab-dashboard-container ">
      <Row className="white-bg lab-dashboard-table-container">
        {enableExportFunctionality && (
          <ExportButtonForDashboard tooltipText="Export Lab Orders" table="lab_order_data" />
        )}
        <Row align="middle">
          <Col span={1}>
            <img src={PAID_ORDER_ICON} className="program-details-section-icon" alt="" />
          </Col>
          <Col span={3} className="program-details-section-name">
            Lab Orders
          </Col>
        </Row>
        <Row className="lab-dashboard-table-data-row ">
          <DataTableWithPagination
            data={labData?.orders || labData?.order_data || []}
            totalDataCount={labData?.total_count ? labData.total_count : 0}
            columns={labDashboardColumns}
            api={fetchLabOrdersData}
            loading={loading}
            otherParams={memorisedOtherParams}
            setOtherParams={setOtherParams}
            disableSticky
          />
        </Row>
      </Row>
    </div>
  )
}
