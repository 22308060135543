import { Form } from "antd"
import {
  getRules,
  getField,
  getDependencies,
  getBottomOfField,
  getStateBtn,
  DEFAULT_LABEL_FILE_LINK_STYLE,
  findCustomSubSection,
} from "./constants"
import { InformationSection } from "../InformationSection"
import { PresignedUrlFileIcon } from "../../PresignedUrlFileIcon"

export const FormSectionBuilder = (props = {}) => {
  const {
    fields = [],
    optionsData = {},
    section = {},
    fetchOptionsBasedOnUserSelection,
    formType = "",
    stepsFilterSpecialityId = "",
    stepsFilterSchemeId = "",
    stepsFilterCustomSteps = {},
    formObj,
    uploadFileParams,
    stateVarActions = () => {},
    stateVars = {},
    fieldSets = {},
    sectionKey = "",
    sectionInformation = "",
  } = props
  const getFieldElement = (field) => {
    const rules = getRules(field)
    const fetchKey = field?.options_key || ""
    const options = optionsData[fetchKey] || []
    let top = ""
    top = field?.top || ""
    const bottom = getBottomOfField(field)
    let chooseLabelSelect = false
    chooseLabelSelect = !field?.step_name?.includes("program")
    if (
      formType === "programApplication" ||
      formType === "createOrder" ||
      formType === "editProgramPrescription" ||
      formType === "editProgramPrescriptionSection" ||
      formType === "masterManagementCreateForm"
    ) {
      chooseLabelSelect = false
    }
    const inputField = getField({
      field,
      options,
      chooseLabelSelect,
      onDropdownValueChange: fetchOptionsBasedOnUserSelection,
      uploadFileParams,
      formObj,
      formType,
    })
    const labelFromApi = field?.step_name_text || field?.step_name_label
    const { multiselect = false } = field
    const fieldType = field?.field_type || ""
    const labelSuffix = field?.step_name_text_suffix?.map((suffixConfig, suffixIndex) => {
      const suffixText = `File ${suffixIndex + 1}`
      const fileId = suffixConfig?.file_id || ""
      return (
        <PresignedUrlFileIcon
          documentId={fileId}
          showAsText
          text={suffixText}
          showLoader
          buttonStyle={DEFAULT_LABEL_FILE_LINK_STYLE}
        />
      )
    })
    const label = (
      <div>
        <span>{labelFromApi}</span>
        <span>{labelSuffix}</span>
      </div>
    )
    const valuePropName = fieldType === "checkbox" && !multiselect ? "checked" : "value"
    if (fieldType === "state_btn") {
      return getStateBtn({ field, stateVarActions, stateVars })
    }
    if (fieldType === "field_set") {
      // eslint-disable-next-line no-use-before-define
      return getFieldSet({ field })
    }
    return (
      <>
        <div className="form-sub-section-name">{top}</div>
        <Form.Item
          required={field?.is_mandatory || false}
          name={field?.step_name || ""}
          key={field?.step_name || ""}
          label={label}
          dependencies={field?.dependencies || getDependencies(field)}
          rules={rules}
          valuePropName={valuePropName}
        >
          {inputField}
        </Form.Item>
        {bottom}
      </>
    )
  }
  const getTopForFieldSet = ({ fieldSetTop = "", stIndex = "", stepIndex = "", stateVarName = "" }) => {
    let top = ""
    if (fieldSetTop && stepIndex === 0 && stIndex === 0) {
      top = (
        <>
          <div className="mb-16">{fieldSetTop}</div>
          <div className="capitalize subSection2">{`${stateVarName} ${stIndex + 1}`}</div>
        </>
      )
    } else if (stepIndex === 0) {
      top = (
        <div className="capitalize subSection2">
          {stateVarName} {stIndex + 1}
        </div>
      )
    }
    return top
  }
  const getFieldSet = ({ field }) => {
    const stateVarName = field?.state_var || ""
    const fieldSetTop = field?.top || ""
    const fSet = fieldSets[stateVarName] || []
    const fSetFieldKeys = fSet.map((step) => step?.step_name)
    const stateVar = stateVars[stateVarName] || {}
    const { maxval = 0 } = stateVar
    const val = maxval
    let sets = ""
    sets = [...Array(val)]
    if (val > 0) {
      sets = sets?.map((_, stIndex) => {
        const st = fSet.map((step, stepIndex) => {
          let stepName = step?.step_name || ""
          let mandatoryDependsOn = ""
          mandatoryDependsOn = []
          if (step?.is_mandatory && stIndex > 0) {
            const otherKeys = fSetFieldKeys.filter((fsfK) => fsfK !== stepName)
            mandatoryDependsOn = otherKeys
          }
          stepName = `${stepName}_${stIndex + 1}`
          const dependencies = [...mandatoryDependsOn]
          return {
            ...step,
            step_name: stepName,
            field_set_key: stateVarName,
            field_set_index: stIndex,
            top: getTopForFieldSet({ fieldSetTop, stIndex, stepIndex, stateVarName }),
            is_mandatory: stIndex === 0 ? step?.is_mandatory : false,
            dependencies: dependencies.map((dep) => `${dep}_${stIndex + 1}`),
            mandatory_depends_on: mandatoryDependsOn.map((dep) => `${dep}_${stIndex + 1}`),
          }
        })
        return st
      })
      sets = sets?.flat() || []
      return sets.map(getFieldElement)
    }
    return <div />
  }
  const getGenericSectionFields = () => fields.map(getFieldElement)
  // caregiver section handling start
  // caregiver section handling end
  // address section handling start
  // address section handling end
  const getAllFields = () => {
    // let allFields = ""
    // allFields = []
    // if (sectionName === "caregiver_info") {
    //   allFields = getCaregiverSectionFields()
    // } else if (sectionName === "address_info") {
    //   allFields = getAddressSectionFields()
    // } else {
    //   allFields = getGenericSectionFields()
    // }
    // return allFields
    const allFields = getGenericSectionFields()
    return allFields
  }
  const hasSubSection = section?.sub_sections?.length > 0
  const getSubSectionFields = () => {
    let allFields = ""
    allFields = []
    allFields = section?.sub_sections
      ?.map(
        (subSection) =>
          subSection.steps ||
          subSection?.dynamic_rx_details_list?.find(
            (rxList) => rxList.program_speciality_id === stepsFilterSpecialityId
          )?.steps ||
          subSection?.custom_scheme_steps?.find((rxList) => rxList.scheme_id === stepsFilterSchemeId)
            ?.steps ||
          subSection?.custom_section?.find((customSubSection) =>
            findCustomSubSection({ customSubSection, allSelectedDropdownValues: stepsFilterCustomSteps })
          )?.steps ||
          []
      )
      ?.flat(1)
    allFields = allFields.map(getFieldElement)
    return allFields
  }
  const sectionFields = hasSubSection ? getSubSectionFields() : getAllFields()
  return (
    <>
      {sectionInformation && <InformationSection key={sectionKey} text={sectionInformation} />}
      {sectionFields}
    </>
  )
}
