import { PresignedUrlFileIcon } from "../components/DLS/PresignedUrlFileIcon"
import { formatDate, formatTime, formatTimeAsAmPm } from "../utils/common"
import { StatusTag } from "../components/DLS/AdverseEventsDashboard/utils/statusTag"

export const getFileIcon = ({ value }) => value && <PresignedUrlFileIcon documentId={value} showLoader />

export const renderFunctions = {
  date: (value) => value && formatDate(value),
  time: (value) => value && formatTime(value),
  timeAsAmPm: (value) => value && formatTimeAsAmPm(value),
  file: (value) => value && getFileIcon({ value }),
  fileList: (fileList = []) => Array.isArray(fileList) && fileList?.map((value) => getFileIcon({ value })),
  booleanToYesNo: (value) => (value ? "Yes" : "No"),
  statusTag: ({ text = "", color = "", backgroundColor = "" }) => (
    <StatusTag text={text} color={color} backgroundColor={backgroundColor} />
  ),
}

export const defaultRenderFunction = (value) => value

export const getRenderFunction = ({ renderFn = "" }) => {
  const renderFunction =
    renderFn && renderFunctions?.[renderFn] ? renderFunctions?.[renderFn] : defaultRenderFunction
  return renderFunction
}
