import { useState, useMemo, useEffect } from "react"
import { Row, Col } from "antd"
import { getPvDataTableUrl, getPvDropdownOptionsUrl } from "../../../api/endpoints"
import { DataTableWithPagination } from "../DataTableWithPagination"
import { useMutateData } from "../../../utils/hooks"
import { ExportButtonForDashboard } from "../ExportButtonForDashboard"
import { formatPvDashboardColumn } from "../PatientProfile/PhysicalVerificationModule/constants"
import "./styles.scss"

const PhysicalVerificationDashboard = () => {
  const [pvData, setPvData] = useState({})
  const [otherParams, setOtherParams] = useState({})
  const memorisedOtherParams = useMemo(() => otherParams, [otherParams])
  const [pvDashboardColumns, setPvDashboardColumns] = useState({})
  const [callApi, { loading }] = useMutateData()
  const [optionsData, setOptionsData] = useState({})

  const extractCustomDropdownKeys = (columns) =>
    (columns ?? [])
      .filter((column) => column?.showCustomDropdown)
      .map((column) => ({
        key: column.key,
        dropdownKey: column.dropdownKey,
      }))

  const fetchOptions = ({ key = "", name }) => {
    const dropdownValues = {}
    return new Promise((resolve) => {
      callApi({
        url: getPvDropdownOptionsUrl(),
        method: "POST",
        payload: { options_key: key },
      }).then((data = {}) => {
        dropdownValues[name] = []
        dropdownValues[name].push(data?.data[key])
        resolve(dropdownValues)
      })
    })
  }

  const processDropdownOptions = (data) => {
    const searchDropdownOptionsViable = {}

    data.forEach((dropdown) => {
      const key = Object.keys(dropdown)[0]
      const options = dropdown[key][0].map(({ id, name }) => ({ id: id.toString(), name }))
      searchDropdownOptionsViable[key.split("_")[0]] = options
    })
    return searchDropdownOptionsViable
  }

  const getAllDropdownData = async () => {
    const dropdownKeyFields = extractCustomDropdownKeys(pvDashboardColumns)
    const allDropdownData = await Promise.all(
      dropdownKeyFields.map((field) => fetchOptions({ key: field?.dropdownKey || "", name: field?.key }))
    )
    setOptionsData(processDropdownOptions(allDropdownData))
  }

  useEffect(() => {
    if (pvDashboardColumns && pvDashboardColumns.length > 0) getAllDropdownData()
  }, [pvDashboardColumns])

  const getPVData = async (params = {}) => {
    let payload = {}
    payload = {
      page_no: params?.page_number || 0,
      ...params,
    }

    delete payload.page_number
    try {
      const { data = {} } = await callApi({
        url: getPvDataTableUrl(),
        method: "POST",
        payload,
      })
      setPvData(data)
      const dynamicColumnsObj = data?.display_columns?.map((column) => {
        const formattedColumn = formatPvDashboardColumn(column)
        return formattedColumn
      })
      setPvDashboardColumns(dynamicColumnsObj)
    } catch (apiError) {
      console.log("Physical Verification Datatable error", apiError)
    }
  }

  return (
    <div className="pv-dashboard-container">
      <Row className="white-bg pv-content-container">
        <Col span={12} className="h-100">
          <Row className="pv-dashboard-cta-container">
            <Col span={6} push={6} className="text-right">
              <ExportButtonForDashboard table="pv_data" tooltipText="Export PV" />
            </Col>
          </Row>

          <Row className="pv-dashboard-table-container">
            <DataTableWithPagination
              data={pvData?.physical_verification_details || []}
              totalDataCount={pvData?.total_count ? pvData.total_count : 0}
              columns={pvDashboardColumns}
              api={getPVData}
              loading={loading}
              otherParams={memorisedOtherParams}
              setOtherParams={setOtherParams}
              customDropdownOptions={optionsData}
              disableSticky
            />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default PhysicalVerificationDashboard
