import { Card, Dropdown, Menu, notification } from "antd"
import { MoreOutlined } from "@ant-design/icons"
import { getAddress } from "../PatientProfileSummary/common"
import { useMutateData } from "../../../../utils/hooks"
import { editPatientByUidUrl } from "../../../../api/endpoints"
import { Loader } from "../../../Loader"
import "./styles.scss"

export const DeliveryAddressCard = (props) => {
  const {
    address = {},
    isCreateFlow = false,
    triggerEdit,
    index,
    afterSave,
    patientId,
    editPatientAllowed,
    // allAddresses = [],
  } = props
  const [apiCall, { loading }] = useMutateData()
  const [toastMessage, contextHolder] = notification.useNotification()
  const isDefault = address?.is_default_address || false
  const showToastMessage = (data) => {
    const { message = "", error = false } = data
    if (error) {
      toastMessage.error({
        key: "editAddressSuccess",
        message: "Error",
        description: message,
      })
    } else {
      toastMessage.success({
        key: "editAddressError",
        message: "Success",
        description: message,
      })
    }
  }
  const editApiCall = async ({ action }) => {
    let addrObj = ""
    addrObj = { ...address, patient_id: patientId }
    let delAddrs = ""
    delAddrs = []
    if (action === "delete") {
      addrObj.is_active = false
      delAddrs = [addrObj]
    }
    if (action === "default") {
      delAddrs = [addrObj]
      addrObj.is_default_address = true
      // delAddrs = allAddresses.map((adObj, adIndex) => ({
      //   ...adObj,
      //   is_default_address: index === adIndex,
      // }))
    }
    const { data = {} } = await apiCall({
      url: editPatientByUidUrl(),
      method: "POST",
      payload: { patient_id: patientId, delivery_addresses: delAddrs },
    })
    showToastMessage(data)
    setTimeout(() => {
      afterSave()
    }, 1000)
  }
  const addressActions = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
    {
      key: "default",
      label: "Make Default",
    },
  ]
  const actionHandler = (clickDetails) => {
    const { key = "" } = clickDetails
    if (key === "edit") {
      triggerEdit({ addrIndex: index })
    } else {
      editApiCall({ action: key })
    }
  }
  return (
    <>
      {contextHolder}
      {loading && <Loader type="semiTransparentFullPage" size={60} />}
      <Card
        bordered
        hoverable={!isCreateFlow}
        className={isDefault ? "patient-active-address" : "patient-inactive-address"}
      >
        {getAddress(address)}
        {!isCreateFlow && editPatientAllowed && (
          <div className="delivery-address-actions-trigger">
            <Dropdown placement="topLeft" overlay={<Menu items={addressActions} onClick={actionHandler} />}>
              <MoreOutlined />
            </Dropdown>
          </div>
        )}
      </Card>
    </>
  )
}
