/**
 *
 * @returns string url
 */
export const fetchProgramSteps = () => `/program/getSteps`
/**
 *
 * @returns string url
 */
export const fetchProgramStepOptions = () => `/program/getOptions`
/**
 *
 * @returns string url
 */
export const applyProgramUrl = () => `/program/applyProgram`
/**
 *
 * @returns string url
 */
export const editPrescriptionUrl = () => `/program/editPrescription`
/**
 *
 * @returns string url
 */
export const getPatientProgramDetailsUrl = () => `/program/getPatientProgramDetails`
/**
 *
 * @returns string url
 */
export const getPresignedUrlApi = () => `/program/getPresignedUrl`
/**
 *
 * @returns string url
 */
export const approvePatientProgramMappingApi = () => `/program/approvePatientProgramMapping`
/**
 *
 * @returns string url
 */
export const rejectPatientProgramMappingApi = () => `/program/rejectPatientProgramMapping`
/**
 *
 * @returns string url
 */
export const patientProgramDashboardApi = () => `/program/patient-program-dashboard`
/**
 *
 * @returns string url
 */
export const getCreateOrderFormApi = () => `/program/getCreateOrderForm`
/**
 *
 * @returns string url
 */
export const getOrderOptionsApi = () => `/program/getOrderOptionsList`
/**
 *
 * @returns string url
 */
export const createOrderApi = () => `/program/createOrder`
/**
 *
 * @returns string url
 */
export const suspendDocumentApi = () => `/program/suspendDocument`
/**
 *
 * @returns string url
 */
export const fetchOrdersList = () => `/program/fetchOrders`
/**
 *
 * @returns string url
 */
export const updateOrderStatus = () => `/program/updateOrderStatus`
/**
 *
 * @returns string url
 */
export const fetchOrdersDashboardUrl = () => `/program/fetchOrdersDashboard`
/**
 *
 * @returns string url
 */
export const closeFocOrderUrl = () => `/program/closeFocOrder`
/**
 *
 * @returns string url
 */
export const digitiseProgramUrl = () => `/program/digitiseProgram`
/**
 *
 * @returns string url
 */
export const digitiseOrderUrl = () => `/program/digitiseOrder`
