import { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Row } from "antd"
import { useMutateData } from "../../../utils/hooks"

import "./style.scss"
import { getCommunicationDataUrl } from "../../../api/endpoints/communication"
import { DataTableWithPagination } from "../DataTableWithPagination"
import { displayColumns, displayColumnsSMS } from "./hardcodeData"
import { formatCommunicationDashboardColumn } from "./constants"
import { getCurrentSubdomain } from "../../../utils/common"

export const CommunicationDashboard = (props) => {
  const { type } = props
  const [ComData, setComData] = useState({})
  const [otherParams, setOtherParams] = useState({})
  const memorisedOtherParams = useMemo(() => otherParams, [otherParams])
  const [callApi, { loading }] = useMutateData()
  const [comDashboardColumns, setComDashboardColumns] = useState({})
  const currentSubdomain = useSelector(getCurrentSubdomain)

  const fetchAllCommunicationData = async (params = {}) => {
    let payload = {}
    payload = {
      page_no: params?.page_number || 0,
      page_size: params?.page_size || 10,
      ...params,
      type,
    }
    delete payload.page_number
    try {
      const { data = [] } = await callApi({
        url: getCommunicationDataUrl(),
        method: "POST",
        payload,
      })
      setComData(data)
      const columnObj = type === "EMAIL" ? displayColumns : displayColumnsSMS

      const dynamicColumnsObj = columnObj?.map((column) => {
        const formattedColumn = formatCommunicationDashboardColumn(column, currentSubdomain, type)
        return formattedColumn
      })
      setComDashboardColumns(dynamicColumnsObj)
    } catch (apiError) {
      console.log("Communication Dashboard Datatable error", apiError)
    }
  }

  useEffect(() => {
    fetchAllCommunicationData(otherParams)
    return () => {
      setOtherParams({})
    }
  }, [type])
  return (
    <div className="communication-dashboard-container ">
      <Row className="communication-dashboard-table-container ">
        <DataTableWithPagination
          key={type}
          data={ComData?.communication_logs_data || []}
          totalDataCount={ComData?.total_count ? ComData.total_count : 0}
          columns={comDashboardColumns}
          api={fetchAllCommunicationData}
          loading={loading}
          otherParams={memorisedOtherParams}
          setOtherParams={setOtherParams}
          disableSticky
          resetFieldsFlag
        />
      </Row>
    </div>
  )
}
