/**
 *
 * @returns string url
 */
export const createTokenEmailUrl = () => `/auth/create_token_email`

/**
 *
 * @returns string url
 */
export const forgotPasswordUrl = () => `/auth/forgot-password`

/**
 *
 * @returns string url
 */
export const resetPasswordUrl = () => `/auth/reset-password`

/**
 *
 * @returns string url
 */
export const verifyPasswordResetLink = () => `/auth/verify-reset-link`

/**
 *
 * @returns string url
 */
export const verifyTokenEmailUrl = () => `/auth/verify_token_email`

/**
 *
 * @returns string url
 */
export const selectAppRoleUrl = () => `/auth/selectAppRole`

/**
 *
 * @returns string url
 */
export const logoutUrl = () => `/auth/logout`
/**
 *
 * @returns string url
 */
export const getRSAPublicKeyUrl = () => `/auth/get-public-key`
