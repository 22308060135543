import { useState, useEffect } from "react"
import { useMutateData } from "../../../../utils/hooks"
import { fetchPatientDetailsForPv, sendPvOtp } from "../../../../api/endpoints"

export const PvPatientDetails = ({ orderId, type = "", updatePatientId, pvid = "" }) => {
  const [patientDetails, setPatientDetails] = useState({})
  const [apiCall] = useMutateData()
  const sendOTP = async ({ patientId }) => {
    await apiCall({
      url: sendPvOtp(),
      method: "POST",
      payload: { patientId, id: pvid },
    })
  }
  const fetchPatientDetails = async () => {
    const { data = {} } = await apiCall({
      url: fetchPatientDetailsForPv(),
      method: "POST",
      payload: { orderId },
    })
    const patientId = data?.id || ""
    updatePatientId(patientId)
    if (patientId && type === "MARK_AS_VERIFIED_FORM") {
      sendOTP({ patientId })
    }
    setPatientDetails(data)
  }
  useEffect(() => {
    if (orderId) {
      fetchPatientDetails()
    }
  }, [orderId])
  return (
    <div className="pv-patient-details-container">
      <div className="pv-title">{patientDetails?.name || ""}</div>
      {patientDetails?.uid && <div className="pv-sub-title">UID{patientDetails?.uid || ""}</div>}
    </div>
  )
}
